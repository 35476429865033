import React from 'react';

import PropTypes from 'prop-types';

export const LoadingElement = ({ hideTitle, title, ...props }) => {
    const titleNode = hideTitle ? null : <p className="text-center">{ title }</p>;

    return (
        <div className="loading-element" style={{ margin: '0 auto' }} {...props}>
            <div className="loader-container" />
            { titleNode }
        </div>
    );
};

LoadingElement.propTypes = {
    hideTitle: PropTypes.bool,
    title: PropTypes.string,
};

LoadingElement.defaultProps = {
    title: 'Loading...',
};

export default LoadingElement;
