import Loadable from 'react-loadable';

import { GetSignedUrlFile } from './components/GetSignedUrlFile';
import isLoadingComponent from './components/IsPageLoading';
import Error500 from './containers/errors/500error';

const UnauthorizedErrorPage = Loadable({
    loader: () => import('@src/containers/errors/UnauthorizedErrorPage'),
    loading: isLoadingComponent,
});

const NoStatesErrorPage = Loadable({
    loader: () => import('@src/containers/errors/NoStatesErrorPage'),
    loading: isLoadingComponent,
});

const AccessDeniedErrorPage = Loadable({
    loader: () => import('@src/containers/errors/AccessDeniedErrorPage'),
    loading: isLoadingComponent,
});

const LoggedOutPage = Loadable({
    loader: () => import('@src/containers/logged-out-page'),
    loading: isLoadingComponent,
});

const WelcomePage = Loadable({
    loader: () => import('@src/containers/welcome-page'),
    loading: isLoadingComponent,
});

const LoginPage = Loadable({
    loader: () => import('@src/containers/login'),
    loading: isLoadingComponent,
});

/**
 * ## Error 404
 *
 * **Link:**
 * - `/erro404`
 *
 * **About:**  Display page can't be found
 */
const Error404 = Loadable({
    loader: () => import('./containers/errors/404error'),
    loading: isLoadingComponent,
});

/**
 * ## Error 403
 *
 * **Link:**
 * - `/authorization-error`
 *
 * **About:** Informs user they can't access this page due
 * to authorization error
 */
const Error403 = Loadable({
    loader: () => import('./containers/errors/403error'),
    loading: isLoadingComponent,
});

/**
 * ## Error 401
 *
 * **Link:**
 * - `/inactivity-error`
 *
 * **About:**  Users login credentials have timed out.
 * Prompt user to relogin
 */
const Error401 = Loadable({
    loader: () => import('./containers/errors/401error'),
    loading: isLoadingComponent,
});

/**
 * ## TrainingAndJobAidsArticles
 *
 * **Link:**
 * - `/training-and-job-aids/:id`
 * - `/training-and-job-aids/elearnings/:id`
 *
 * **About:** Training and Jobs Aids Articles: Training/ Webinars/ Job aids Articles
 *
 */
const TrainingAndJobAidsArticles = Loadable({
    loader: () => import('./containers/trainings-and-job-aids/id'),
    loading: isLoadingComponent,
});

const TrainingArticlePage = Loadable({
    loader: () => import('./containers/trainings-and-job-aids/elearnings/id'),
    loading: isLoadingComponent,
});

const JobAidPage = Loadable({
    loader: () => import('./containers/trainings-and-job-aids/job_aids/id'),
    loading: isLoadingComponent,
});

const WebinarPage = Loadable({
    loader: () => import('./containers/trainings-and-job-aids/webinars/id'),
    loading: isLoadingComponent,
});

/**
 * ## JobAidsSearchPages
 *
 * **Link:**
 * - `/training-and-job-aids/job_aids/:id`
 * - `/training-and-job-aids/job_aids/search:id`
 *
 * **About:** Training and Jobs Aids Articles: Training/ Webinars/ Job aids Articles
 *
 */
const JobAidsSearchPages = Loadable({
    loader: () => import('./containers/trainings-and-job-aids/job_aids'),
    loading: isLoadingComponent,
});

/**
 * ## TrainingAndJobAidsLiveWebinarSearchPages
 *
 * **Link:**
 * - `/training-and-job-aids/webinars/search:id`
 *
 * **About:** Training and Jobs Aids Webinars Search Page: Index View
 *
 */
const TrainingAndJobAidsLiveWebinarSearchPages = Loadable({
    loader: () => import('./containers/trainings-and-job-aids/webinars'),
    loading: isLoadingComponent,
});

/**
 * ## TrainingAndJobAidsSearchPages
 *
 * **Link:**
 * - `/training-and-job-aids/search`
 * - `/training-and-job-aids/search/:id`
 *
 * **About:** Training and Jobs Aids Articles Search Page: Index View
 *
 */
const TrainingAndJobAidsSearchPages = Loadable({
    loader: () => import('./containers/trainings-and-job-aids/elearnings'),
    loading: isLoadingComponent,
});

/**
 * ## TrainingAndJobAidsTake20Page
 *
 * **Link:**
 * - `/training-and-job-aids/take20`
 *
 * **About:** Training and Jobs Aids Take20 Page: Index View
 *
 */
const TrainingAndJobAidsTake20Page = Loadable({
    loader: () => import('./containers/trainings-and-job-aids/take20'),
    loading: isLoadingComponent,
});

/**
 * ## TrainingAndJobAids
 *
 * **Link:**
 * - `/training-and-job-aids`
 *
 * **About:** Training and Jobs Aids Home:
 * Leads to Training - or - Webinars search pages
 *
 */
const TrainingAndJobAids = Loadable({
    loader: () => import('./containers/trainings-and-job-aids'),
    loading: isLoadingComponent,
});

/**
 * ## Home
 *
 * **Link:**
 * - `/`
 *
 * **About:** Home page
 *
 */
const Home = Loadable({
    loader: () => import('./containers/home'),
    loading: isLoadingComponent,
});

/**
 * ## CredentialChecker
 *
 * **Link:**
 * - `N/A`
 *
 * **About:** This is the container that checks user has JWT Token
 *
 */
const CredentialChecker = Loadable({
    loader: () => import('./containers/credentialChecker'),
    loading: isLoadingComponent,
});

/**
 * ## Guidesandmanuals
 *
 * **Link:**
 * - `/guides-and-rate-bulletins`
 *
 * **About:** Underwriting Guidelines and Rate Bulletins Index Page
 *
 */
const Guidesandmanuals = Loadable({
    loader: () => import('./containers/guides'),
    loading: isLoadingComponent,
});

/**
 * ## GuideOrManualView
 *
 * **Link:**
 * - `/guides-and-rate-bulletins/:id`
 *
 * **About:** Underwriting Guidelines and Rate Bulletins Article: View Page
 *
 */
const GuideOrManualView = Loadable({
    loader: () => import('./containers/guides/id'),
    loading: isLoadingComponent,
});

/**
 * ## ProductsSelectionPage
 *
 * **Link:**
 * - `/product`
 *
 * **About:** Products Home Page: Index Selection Page
 *
 */
const ProductsSelectionPage = Loadable({
    loader: () => import('./containers/products'),
    loading: isLoadingComponent,
});

/**
 * ## ProductsNewsAndUpdatesPage
 *
 * **Link:**
 * - `/products/product-news-and-updates/`
 * - `/products/product-news-and-updates?category-id[]=`
 *
 * **About:** Products Articles Search: Index Page
 *
 */
const ProductsNewsAndUpdatesPage = Loadable({
    loader: () => import('./containers/products/product-news-and-updates'),
    loading: isLoadingComponent,
});

/**
 * ## ProductsArticles
 *
 * **Link:**
 * - `/products/product-news-and-updates/:id`
 *
 * **About:** Products Articles: Article View Page
 *
 */
const ProductsArticles = Loadable({
    loader: () => import('./containers/products/product-news-and-updates/id'),
    loading: isLoadingComponent,
});

/**
 * ## ProductsAProductsAvailMaprticles
 *
 * **Link:**
 * - `/products/product-availability-map`
 * - `/products/product-availability-map/:id`
 *
 * **About:** Products Availability Map: View Page
 *
 */
const ProductsAvailMap = Loadable({
    loader: () => import('./containers/products/product-availability-map'),
    loading: isLoadingComponent,
});

/**
 * ## ProductsDisplaySingleProduct
 *
 * **Link:**
 * - `/products/classic/:id`
 * - `/products/:id`
 *
 * **About:** Products: View Page. Classic or New
 *
 */
const ProductsDisplaySingleProduct = Loadable({
    loader: () => import('./containers/products/id'),
    loading: isLoadingComponent,
});

/**
 * ## AlertsPage
 *
 * **Link:**
 * - `/alerts`
 *
 * **About:** Alerts: Index Page
 *
 */
const AlertsPage = Loadable({
    loader: () => import('./containers/alert'),
    loading: isLoadingComponent,
});

/**
 * ## AlertsArticlePage
 *
 * **Link:**
 * - `/alerts/:id`
 * - `/products/:id`
 *
 * **About:** Alerts Articles: View Page
 *
 */
const AlertsArticlePage = Loadable({
    loader: () => import('./containers/alert/id'),
    loading: isLoadingComponent,
});

/**
 * ## ContactUsPage
 *
 * **Link:**
 * - `/contact-us`
 *
 * **About:** Contact Us: Displays all Contact Forms
 *
 */
const ContactUsPage = Loadable({
    loader: () => import('./containers/contact-us'),
    loading: isLoadingComponent,
});

const FavoritePagesPage = Loadable({
    loader: () => import('./components/FavoritePages'),
    loading: isLoadingComponent,
});

// const RSMAgent360Page = Loadable({
//     loader: () => import('./containers/rsm/agent360'),
//     loading: isLoadingComponent,
// });

/**
 * ## RSMArticles
 *
 * **Link:**
 * - `/rsm/field-management-news/:id`
 *
 * **About:** Field Managment News - Article pages
 *
 */
// const RSMArticles = Loadable({
//    loader: () => import('./containers/rsm/field-management-news/id'),
//    loading: isLoadingComponent,
// });

/**
 * ## RSMFieldManagmentPage
 *
 * **Link:**
 * - `/rsm/field-management-news`
 *
 * **About:** RSM Field Management News Index view
 *
 */
// const RSMFieldManagmentPage = Loadable({
//     loader: () => import('./containers/rsm/field-management-news'),
//     loading: isLoadingComponent,
// });

/**
 * ## RSMPortalPage
 *
 * **Link:**
 * - `/rsm`
 *
 * **About:** RSM Home Page Containing all rsm linked pages
 *
 */
// const RSMPortalPage = Loadable({
//     loader: () => import('./containers/rsm'),
//     loading: isLoadingComponent,
// });

/**
 * ## Agent360ContainerPage
 *
 * **Link:**
 * - `/agent360`
 * - `/agent360/:id`
 *
 * **About:** Agent360 Container for agent360 sub pages
 *
 */
const Agent360ContainerPage = Loadable({
    loader: () => import('./containers/agent360'),
    loading: isLoadingComponent,
});

/**
 * ## Agent360ExpectPage
 *
 * **Link:**
 * - `/agent360`
 * - `/agent360/what-to-expect`
 *
 * **About:** Agent360 - What to expect sub page -default agent360 sub page
 *
 */
const Agent360ExpectPage = Loadable({
    loader: () => import('./containers/agent360/agent360_expect'),
    loading: isLoadingComponent,
});

/**
 * ## Agent360ProductsPage
 *
 * **Link:**
 * - `/agent360/new-products`
 *
 * **About:** Agent360 - New Products sub page
 */
const Agent360ProductsPage = Loadable({
    loader: () => import('./containers/agent360/product/product'),
    loading: isLoadingComponent,
});

/**
 * ## Agent360TrainingPage
 *
 * **Link:**
 * - `/agent360/training`
 *
 * **About:** Agent360 - Trainings sub page
 */
const Agent360TrainingPage = Loadable({
    loader: () => import('./containers/agent360/agent360_training_page'),
    loading: isLoadingComponent,
});

const SearchPage = Loadable({
    loader: () => import('./containers/search'),
    loading: isLoadingComponent,
});

const SitemapPage = Loadable({
    loader: () => import('./components/sitemap/SitemapPage'),
    loading: isLoadingComponent,
});

const DigitalAccessibilityPage = Loadable({
    loader: () => import('./components/digital_accessibility/DigitalAccessibilityPage'),
    loading: isLoadingComponent,
});

const CommunicationRequestForms = Loadable({
    loader: () => import('./containers/CommunicationRequestForms/CommunicationRequestForms'),
    loading: isLoadingComponent,
});

const SocialSharingsPage = Loadable({
    loader: () => import('./components/SocialSharings'),
    loading: isLoadingComponent,
});

const CollateralsPage = Loadable({
    loader: () => import('./components/collaterals/collaterals_page'),
    loading: isLoadingComponent,
});

const CollateralTemplatesPage = Loadable({
    loader: () => import('./components/collaterals/collateral_templates_page'),
    loading: isLoadingComponent,
});

const CollateralTemplatePage = Loadable({
    loader: () => import('./components/collaterals/collateral_template_page'),
    loading: isLoadingComponent,
});

const CheckoutPage = Loadable({
    loader: () => import('./components/collateral_requests/CheckoutPage'),
    loading: isLoadingComponent,
});

const CoBrandedVideosPage = Loadable({
    loader: () => import('@src/components/co_branded_videos'),
    loading: isLoadingComponent,
});

const SalesforceKnowledgebase = Loadable({
    loader: () => import('./containers/salesforce'),
    loading: isLoadingComponent,
});

export const routes = [
    {
        component: UnauthorizedErrorPage,
        path: '/unauthorized',
        excat: true,
    },
    {
        component: NoStatesErrorPage,
        path: '/no-states',
        excat: true,
    },
    {
        component: AccessDeniedErrorPage,
        path: '/access-denied',
        excat: true,
    },
    {
        component: LoggedOutPage,
        path: '/logged-out',
        excat: true,
    },
    {
        component: WelcomePage,
        path: '/welcome',
        excat: true,
    },
    {
        component: LoginPage,
        path: '/login',
        exact: true,
    },
    {
        component: CredentialChecker,
        path: '',
        exact: true,
        routes: [
            {
                component: GetSignedUrlFile,
                path: '/attachments',
                exact: false,
            },
            {
                component: FavoritePagesPage,
                path: '/favorites',
                exact: true,
            },
            {
                component: AlertsArticlePage,
                path: '/alerts/:id',
                exact: true,
            },
            {
                component: AlertsPage,
                path: '/alerts',
                exact: true,
            },
            {
                component: CommunicationRequestForms,
                path: '/communication-request-forms',
                exact: true,
            },
            // {
            //     component: RSMAgent360Page,
            //     path: '/field-management/agent360/:id',
            // },
            // {
            //     component: RSMAgent360Page,
            //     path: '/field-management/agent360',
            // },
            // {
            //     component: RSMFieldManagmentPage,
            //     path: '/field-management/field-management-news',
            //     exact: true,
            // },
            // {
            //     component: RSMArticles,
            //     path: '/field-management/field-management-news/:id',
            //     exact: true,
            // },
            // {
            //     component: RSMArticles,
            //     path: '/field-management/:id',
            //     exact: true,
            // },
            // {
            //     component: RSMPortalPage,
            //     path: '/field-management',
            //     exact: true,
            // },
            {
                component: TrainingAndJobAidsLiveWebinarSearchPages,
                path: '/training-and-job-aids/webinars/search:id',
            },
            {
                component: TrainingAndJobAidsLiveWebinarSearchPages,
                path: '/training-and-job-aids/webinars/search',
            },
            {
                component: TrainingAndJobAidsLiveWebinarSearchPages,
                path: '/training-and-job-aids/webinars/',
                exact: true,
            },
            {
                component: TrainingAndJobAidsSearchPages,
                path: '/training-and-job-aids/elearnings/search',
                exact: true,
            },
            {
                component: TrainingAndJobAidsSearchPages,
                path: '/training-and-job-aids/elearnings',
                exact: true,
            },
            {
                component: TrainingAndJobAidsTake20Page,
                path: '/training-and-job-aids/take20',
                exact: true,
            },
            {
                component: JobAidsSearchPages,
                path: '/training-and-job-aids/job-aids',
                exact: true,
            },
            {
                component: JobAidsSearchPages,
                path: '/training-and-job-aids/job-aids/search',
            },
            {
                component: JobAidsSearchPages,
                path: '/training-and-job-aids/job-aids/search:id',
            },
            {
                component: JobAidPage,
                path: '/training-and-job-aids/job-aids/:id',
            },
            {
                component: WebinarPage,
                path: '/training-and-job-aids/webinars/:id',
                exact: true,
            },
            {
                component: TrainingArticlePage,
                path: '/training-and-job-aids/elearnings/:id',
                exact: true,
            },
            {
                component: TrainingAndJobAidsArticles,
                path: '/training-and-job-aids/:id',
            },
            {
                component: TrainingAndJobAids,
                path: '/training-and-job-aids',
                exact: true,
            },
            {
                component: GuideOrManualView,
                path: '/guides-and-rate-bulletins/:id',
            },
            {
                component: Guidesandmanuals,
                path: '/guides-and-rate-bulletins',
                exact: true,
            },
            {
                component: ProductsNewsAndUpdatesPage,
                path: '/products/product-news-and-updates/search:id',
            },
            {
                component: ProductsNewsAndUpdatesPage,
                path: '/products/product-news-and-updates/search',
            },
            {
                component: ProductsArticles,
                path: '/products/product-news-and-updates/:id',
            },
            {
                component: ProductsAvailMap,
                path: '/products/product-availability-map/:id',
            },
            {
                component: ProductsAvailMap,
                path: '/products/product-availability-map',
            },
            {
                component: ProductsNewsAndUpdatesPage,
                path: '/products/product-news-and-updates:id',
            },
            {
                component: ProductsNewsAndUpdatesPage,
                path: '/products/product-news-and-updates/',
            },
            {
                component: ProductsDisplaySingleProduct,
                path: '/products/classic/:id',
                exact: true,
            },
            {
                component: ProductsDisplaySingleProduct,
                path: '/products/agent360/:id',
                exact: true,
            },
            {
                component: ProductsDisplaySingleProduct,
                path: '/products/:id',
                exact: true,
            },
            {
                component: ProductsSelectionPage,
                path: '/products',
                exact: true,
            },
            {
                component: Agent360ContainerPage,
                path: '/agent360/:id',
                routes: [
                    {
                        component: Agent360ExpectPage,
                        path: '/agent360/what-to-expect',
                    },
                    {
                        component: Agent360ProductsPage,
                        path: '/agent360/new-products',
                    },
                    {
                        component: Agent360ProductsPage,
                        path: '/agent360/product-overview',
                    },
                    {
                        component: Agent360TrainingPage,
                        path: '/agent360/training',
                    },
                ],
            },
            {
                component: SearchPage,
                path: '/search',
                exact: true,
            },
            {
                component: Agent360ContainerPage,
                path: '/agent360',
                exact: true,
            },
            {
                component: SocialSharingsPage,
                path: '/ready-to-use-social-media-posts',
                exact: true,
            },
            {
                component: CollateralsPage,
                path: '/marketing-collateral',
                exact: true,
            },
            {
                component: CollateralTemplatesPage,
                path: '/marketing-collateral/co-op-marketing-center',
                exact: true,
            },
            {
                component: CollateralTemplatePage,
                path: '/marketing-collateral/co-op-marketing-center/:id',
                exact: true,
            },
            {
                component: CheckoutPage,
                path: '/marketing-collateral/co-op-marketing-center/orders/:id',
                exact: true,
            },
            {
                component: ContactUsPage,
                path: '/contact-us',
                exact: true,
            },
            {
                component: CoBrandedVideosPage,
                path: '/co-branded-videos',
                excat: true,
            },
            {
                component: SitemapPage,
                path: '/sitemap',
                excat: true,
            },
            {
                component: DigitalAccessibilityPage,
                path: '/digital-accessibility',
                excat: true,
            },
            {
                component: SalesforceKnowledgebase,
                path: '/ask-foremost',
                exact: true,
            },
            {
                component: Error403,
                path: '/authorization-error',
                exact: true,
            },
            {
                component: Error500,
                path: '/server-down-error',
                exact: true,
            },
            {
                component: Error401,
                path: '/inactivity-error',
                exact: true,
            },
            {
                component: Home,
                path: '/',
                exact: true,
            },
            {
                component: Error404,
                path: '*',
            },
        ],
    },
];
